import React from 'react';
import { useScriptLoader } from '../hooks/useScriptLoader';

function Checkout() {
  useScriptLoader('app');

  return (
    <div  className="mt-[64px]"  id="app"></div>
  );
}

export default Checkout; 