import React, { useState, useEffect, useRef } from 'react';
import toast from 'react-hot-toast';

const FORMSPARK_ACTION_URL = "https://submit-form.com/kRjL8Zk1h";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const mapRef = useRef(null);
  
  useEffect(() => {
    // Initialiser la carte Google Maps
    if (window.google && mapRef.current) {
      // Définir les coordonnées à l'intérieur du useEffect
      const center = {
        lat: 53.340775,
        lng: -2.860689
      };
      
      const map = new window.google.maps.Map(mapRef.current, {
        center,
        zoom: 12,
        mapTypeId: window.google.maps.MapTypeId.ROADMAP
      });
      
      // Ajouter un marqueur
      new window.google.maps.Marker({
        position: center,
        map,
        title: "Skypark Location"
      });
    }
  }, []); // Plus besoin de dépendance center

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    const newErrors = {};
    
    // Validation
    if (!formData.name.trim()) {
      newErrors.name = 'This field is required.';
    }
    if (!formData.email.trim()) {
      newErrors.email = 'This field is required.';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Please enter a valid email address.';
    }
    if (!formData.message.trim()) {
      newErrors.message = 'This field is required.';
    }

    if (Object.keys(newErrors).length === 0) {
      const toastId = toast.loading('Sending message...');
      try {
        await fetch(FORMSPARK_ACTION_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(formData),
        });
        
        toast.success('Message sent successfully!', {
          id: toastId,
          duration: 2000,
          style: {
            background: '#4CAF50',
            color: '#fff',
          },
          icon: '✅',
        });
        
        setFormData({ name: '', email: '', message: '' });
      } catch (error) {
        console.error('Error submitting form:', error);
        toast.error('Failed to send message. Please try again.', {
          id: toastId,
          duration: 2000,
          style: {
            background: '#f44336',
            color: '#fff',
          },
          icon: '❌',
        });
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setErrors(newErrors);
      setIsSubmitting(false);
    }
  };

  return (
    <div className="pt-32 pb-16">
      {/* Header */}
      <div className="max-w-7xl mx-auto px-4">
        <h1 className="text-4xl md:text-5xl font-light text-center mb-8">Contact us</h1>
        <div className="max-w-2xl mx-auto text-center space-y-4 mb-16">
          <p className="text-lg text-gray-600">
            Our booking system is fully automated, however, if there is something you can't find or if you just want to confirm or check something about your booking, please do not hesitate to contact us.
          </p>
          <p className="text-lg text-gray-600">
            We understand that parking your car safely is just one small part of your travel arrangements, but we aim to ensure it's one element you won't have to worry about and will strive to make sure we have your full confidence.
          </p>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4">
        <div className="grid md:grid-cols-3 gap-8">
          {/* Contact Form */}
          <div className="md:col-span-2">
            <div className="bg-white shadow-sm p-8">
              <h2 className="text-2xl font-medium mb-6">GET IN TOUCH</h2>
              <form 
                onSubmit={handleSubmit} 
                className="space-y-6"
              >
                <div>
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className={`w-full p-3 border ${errors.name ? 'border-red-500' : 'border-gray-300'} rounded-sm focus:outline-none focus:ring-1 focus:ring-skypark-blue`}
                  />
                  {errors.name && <p className="mt-1 text-sm text-red-500">{errors.name}</p>}
                </div>

                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className={`w-full p-3 border ${errors.email ? 'border-red-500' : 'border-gray-300'} rounded-sm focus:outline-none focus:ring-1 focus:ring-skypark-blue`}
                  />
                  {errors.email && <p className="mt-1 text-sm text-red-500">{errors.email}</p>}
                </div>

                <div>
                  <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    rows="6"
                    value={formData.message}
                    onChange={handleChange}
                    className={`w-full p-3 border ${errors.message ? 'border-red-500' : 'border-gray-300'} rounded-sm focus:outline-none focus:ring-1 focus:ring-skypark-blue`}
                  ></textarea>
                  {errors.message && <p className="mt-1 text-sm text-red-500">{errors.message}</p>}
                </div>

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={`w-full bg-blue-600 text-white py-3 px-6 rounded-sm hover:bg-blue-700 transition-colors duration-300 ${
                    isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                >
                  {isSubmitting ? 'Sending...' : 'Send'}
                </button>
              </form>
            </div>
          </div>

          {/* Contact Info */}
          <div className="space-y-6">
            <div className="bg-white shadow-sm p-8">
              <h3 className="text-lg font-medium mb-4">Address:</h3>
              <p className="text-gray-600">
                Unit 25 Owen Drive, SKYPARK<br />
                International L24 1YL
              </p>
            </div>

            <div className="bg-white shadow-sm p-8">
              <h3 className="text-lg font-medium mb-4">Contact Details:</h3>
              <div className="space-y-2">
                <p className="text-gray-600">
                  <span className="font-medium">Phone:</span>{' '}
                  <a href="tel:+441514480300" className="text-skypark-blue hover:underline">
                    +44 (0)151 448 0300
                  </a>
                </p>
                <p className="text-gray-600">
                  <span className="font-medium">Email:</span>{' '}
                  <a href="mailto:finance@skypark.co.uk" className="text-skypark-blue hover:underline">
                    finance@skypark.co.uk
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Map */}
        <div className="mt-12 h-[400px] relative rounded overflow-hidden shadow-sm">
          <div 
            ref={mapRef}
            className="w-full h-full"
          />
        </div>
      </div>
    </div>
  );
};

export default Contact; 