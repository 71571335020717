import React from 'react';
import { Link } from 'react-router-dom';

const ButtonLink = ({ to, label, ariaLabel }) => (
  <Link 
    to={to} 
    className="group relative block bg-white"
    role="button"
    aria-label={ariaLabel}
  >
    <div className="relative flex items-center justify-between p-4 border border-gray-200 group-hover:border-skypark-blue transition-all duration-500">
      <span className="font-medium tracking-wide text-gray-700 group-hover:text-skypark-blue transition-colors duration-500">
        {label}
      </span>
      <div className="flex items-center space-x-2">
        <span className="relative z-10 text-skypark-blue transform translate-x-0 transition-transform duration-500 group-hover:translate-x-1">
          →
        </span>
        <div className="absolute inset-y-0 right-0 w-0 bg-gray-100 transition-all duration-500 ease-out group-hover:w-16" />
      </div>
    </div>
  </Link>
);

const About = () => {
  return (
    <section 
      className="py-16 px-4"
      itemScope 
      itemType="https://schema.org/Organization"
    >
      <h2 className="text-3xl text-center mb-12" id="about-section">About Us</h2>
      
      <div className="max-w-6xl mx-auto grid md:grid-cols-2 gap-8">
        {/* Left Column - Text Content */}
        <div className="space-y-6 text-gray-600 px-7 md:px-4">
          <p itemProp="description">
            Skypark is the number one choice for thousands of customers looking for a convenient car parking service. We have proudly been parking your cars since 2004, and we pride ourselves on being competitively priced. Skypark is the one for you.
          </p>
          
          <p>
            Parking over 100,000 cars over the years means you can always rely on us to strive and deliver the very best service we can possibly offer. We work hard to ensure that, as you head off on your travels, you are confident that your vehicle is being cared for and we do it all with a friendly and approachable attitude.
          </p>
          
          <p>
            We have an experienced team of drivers and support vehicles in constant contact with our head office. At Skypark, we work tirelessly to provide a service that is affordable, convenient, flexible and friendly. The next time you fly away, be sure to leave your vehicle with us and see for yourself.
          </p>
          
          <p className="font-medium" itemProp="slogan">
            Skypark - Parking Confidence
          </p>
          <meta itemProp="name" content="Skypark" />
          <meta itemProp="foundingDate" content="2004" />
        </div>

        {/* Right Column - Buttons */}
        <div className="space-y-2.5 px-7 md:px-4">
          <ButtonLink 
            to="/location"
            label="VIEW OUR LOCATION"
            ariaLabel="View our location"
          />
          
          {/* <ButtonLink 
            to="/reservations"
            label="BOOK A SPACE"
            ariaLabel="Book a parking space"
          />
           */}
          <ButtonLink 
            to="/contact"
            label="CONTACT US NOW"
            ariaLabel="Contact us now"
          />
          
          <ButtonLink 
            to="/faqs"
            label="FAQ"
            ariaLabel="View frequently asked questions"
          />
        </div>
      </div>
    </section>
  );
};

export default About; 