/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useScriptLoader } from '../hooks/useScriptLoader';

const Hero = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  useScriptLoader('App');
  
  const slides = [
    {
      url: "https://www.skypark.co.uk/app/web/upload/medium/1-6-1707745508.jpg",
      alt: "Skypark Airport Parking at Liverpool John Lennon Airport"
    },
    {
      url: "https://www.skypark.co.uk/app/web/upload/medium/liverpool-banner-2-6-1563444884-7-1706013496.jpg",
      alt: "Airport Parking at Skypark Liverpool John Lennon Airport"
    }
  ];

  useEffect(() => {
    const carouselTimer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, 5000);

    return () => {
      clearInterval(carouselTimer);
    };
  }, []);

  return (
    <div className="relative h-[1100px] xl:h-[700px]">
      {/* Background Carousel */}
      <div className="absolute inset-0 overflow-hidden">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`absolute inset-0 transition-opacity duration-1000 ${
              currentSlide === index ? 'opacity-100' : 'opacity-0'
            }`}
          >
            <div
              className="absolute inset-0 bg-cover bg-center bg-no-repeat"
              style={{ backgroundImage: `url(${slide.url})` }}
              aria-label={slide.alt}
            />
          </div>
        ))}
      </div>

      {/* Blue overlay */}
      <div className="absolute inset-0 bg-[#1e293b] bg-opacity-80" />

      {/* Content */}
      <div className="relative h-full flex items-center justify-center">
        <div className="container mx-auto px-4 text-center">
          <div className="max-w-3xl mx-auto">
            <h1 className="text-4xl md:text-6xl font-bold mb-4 text-white" itemProp="name">
              Airport Parking
            </h1>
            <p className="text-xl md:text-2xl italic text-white" itemProp="description">
              Vehicle drop-off and pickup 24/7 - 365
            </p>

            <meta itemProp="provider" content="Skypark" />
            <meta itemProp="serviceType" content="Airport Parking" />
          </div>
        <div className='mx-auto w-full max-w-7xl text-black mt-12' id="App"></div>
        </div>
      </div>

      {/* Slide Indicators */}
      {/* <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 flex space-x-2">
        {slides.map((_, index) => (
          <button
            key={index}
            className={`w-3 h-3 rounded-full transition-all duration-300 ${
              currentSlide === index ? 'bg-white scale-125' : 'bg-white/50'
            }`}
            onClick={() => setCurrentSlide(index)}
            aria-label={`Go to slide ${index + 1}`}
          />
        ))}
      </div> */}
    </div>
  );
};

export default Hero; 