import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const FaqItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200">
      <button
        className="w-full py-5 flex justify-between items-center hover:text-skypark-blue transition-colors duration-200"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-left font-medium">{question}</span>
        <svg
          className={`w-5 h-5 transform transition-transform duration-200 ${
            isOpen ? 'rotate-180' : ''
          }`}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
      <div
        className={`overflow-hidden transition-all duration-200 ${
          isOpen ? 'max-h-96 pb-5' : 'max-h-0'
        }`}
      >
        <p className="text-gray-600 leading-relaxed">{answer}</p>
      </div>
    </div>
  );
};

const Faqs = () => {
  const faqs = [
    {
      question: "When are you open?",
      answer: "You can drop off or collect your vehicle 24/7, 365 days of the year and all services are always available."
    },
    {
      question: "Are there any height or size restrictions for vehicles?",
      answer: "There are no height or size restrictions, but if you have a particularly large vehicle you wish to park, please do contact us with the exact details so that we can ensure you receive the correct service. We can take commercial vehicles up to transit size."
    },
    {
      question: "Is there any extra charge for commercial vehicles?",
      answer: "Commercial vehicles up to transit size will be charged an additional 25%, of their original booking fee, on arrival."
    },
    {
      question: "Are there any electrical charging points at your carpark?",
      answer: "Unfortunately at this time we do not have any vehicle charging points."
    },
    {
      question: "What payment methods do you accept?",
      answer: "For online booking we accept all major debit/credit cards and PayPal™. If you are booking on a mobile device we also accept Apple Pay™ and Google Pay™ if you have one of these set up on your device. We can also take card and cash payments for walk in bookings."
    }
  ];

  return (
    <div className="pt-32 pb-16">
      {/* Header */}
      <div className="max-w-7xl mx-auto px-4">
        <h1 className="text-4xl md:text-5xl font-light text-center mb-8">FAQs</h1>
        <p className="text-center text-lg text-gray-600 max-w-2xl mx-auto mb-16">
          If your query is not answered here, please do feel free to contact us, using the details in the footer below or via our contact page.
        </p>
      </div>

      {/* FAQs Section */}
      <div className="max-w-3xl mx-auto px-4">
        <div className="bg-white shadow-sm rounded-sm">
          {faqs.map((faq, index) => (
            <FaqItem
              key={index}
              question={faq.question}
              answer={faq.answer}
            />
          ))}
        </div>

        {/* Ready to Book Section */}
        <div className="mt-12 text-center">
          <p className="text-gray-600 mb-4">
            If you are ready to book, please click{' '}
            <Link to="/" className="text-skypark-blue hover:underline">
              HERE
            </Link>{' '}
            to proceed to the booking page.
          </p>
          <Link
            to="/contact"
            className="inline-block bg-skypark-blue text-white px-8 py-3 rounded hover:bg-blue-700 transition-colors duration-300"
          >
            Contact Us
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Faqs; 