import React from 'react';
import { useScriptLoader } from '../hooks/useScriptLoader';

function AirportParkingResults() {
  useScriptLoader();

  return (
    <div className="mt-[64px]" id="app"></div>
  );
}

export default AirportParkingResults; 