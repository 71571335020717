import React from 'react';

const Features = () => {
  return (
    <section 
      className="py-16  px-7 md:px-4 bg-gray-50"
      aria-labelledby="features-heading"
    >
      <h2 
        id="features-heading" 
        className="text-3xl text-center mb-16"
        itemProp="slogan"
      >
        Skypark - Parking Confidence
      </h2>
      
      <div 
        className="max-w-6xl mx-auto grid md:grid-cols-3 gap-12"
        itemScope 
        itemType="https://schema.org/ItemList"
      >
        {/* Trusted */}
        <div 
          className="flex flex-col items-center text-center"
          itemScope 
          itemType="https://schema.org/Service"
          itemProp="itemListElement"
        >
          <div className="w-20 h-20 mb-6" aria-hidden="true">
            <svg className="w-full h-full text-skypark-blue" viewBox="0 0 24 24" fill="currentColor">
              <path d="M12 2L1 9.5l11 7.5 11-7.5L12 2zm0 18l-9-6.1v-2L12 18l9-6.1v2L12 20z"/>
            </svg>
          </div>
          <h3 className="text-xl font-medium mb-4" itemProp="name">TRUSTED</h3>
          <p className="text-gray-600" itemProp="description">
            Thousands of valued customers have been happy to leave their vehicle in our safe hands.
          </p>
        </div>

        {/* Customer Service */}
        <div 
          className="flex flex-col items-center text-center"
          itemScope 
          itemType="https://schema.org/Service"
          itemProp="itemListElement"
        >
          <div className="w-20 h-20 mb-6" aria-hidden="true">
            <svg className="w-full h-full text-skypark-blue" viewBox="0 0 24 24" fill="currentColor">
              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"/>
            </svg>
          </div>
          <h3 className="text-xl font-medium mb-4" itemProp="name">CUSTOMER SERVICE</h3>
          <p className="text-gray-600" itemProp="description">
            Our customer service is second to none. Available around the clock, our team of experts are always on hand to help.
          </p>
        </div>

        {/* Book 24 Hours */}
        <div 
          className="flex flex-col items-center text-center"
          itemScope 
          itemType="https://schema.org/Service"
          itemProp="itemListElement"
        >
          <div className="w-20 h-20 mb-6" aria-hidden="true">
            <svg className="w-full h-full text-skypark-blue" viewBox="0 0 24 24" fill="currentColor">
              <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm.5-13H11v6l5.25 3.15.75-1.23-4.5-2.67z"/>
            </svg>
          </div>
          <h3 className="text-xl font-medium mb-4" itemProp="name">BOOK 24 HOURS</h3>
          <p className="text-gray-600" itemProp="description">
            You can book whenever is most convenient to you, as we operate a 24 hour booking system. However, bookings do need to be at least 48 hours prior to travelling.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Features; 