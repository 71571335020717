import React from 'react';

const AboutUs = () => {
  return (
    <div className="pt-32 pb-16">
      {/* Hero Section */}
      <div className="max-w-7xl mx-auto px-4">
        <h1 className="text-4xl md:text-5xl font-light text-center mb-12">About us</h1>
        
        <div className="max-w-4xl mx-auto space-y-8 text-gray-600">
          {/* Main Description */}
          <p className="text-lg leading-relaxed">
            For the greatest, competitively priced airport car parking in Liverpool, 
            Skypark is the one for you. Rely on us to take the stress out of parking 
            as we endeavour to deliver the very best service we can. We put in the 
            hard work necessary, in order to look after your vehicle as you head on 
            your travels, and we do it all with a friendly and approachable attitude.
          </p>
          
          <p className="text-lg leading-relaxed">
            We have an experienced team of drivers and support vehicles in constant 
            contact with our head office. At Skypark, we pride ourselves on running 
            a service that is affordable, convenient, flexible and friendly. The next 
            time you fly away, be sure to leave your vehicle with us and see for yourself.
          </p>
        </div>
      </div>

      {/* Liverpool Airport Section with Image */}
      <div className="bg-gray-50 mt-16 py-16">
        <div className="max-w-7xl mx-auto px-4">
          <div className="max-w-4xl mx-auto">
            <h2 className="text-3xl font-medium mb-8">
              LIVERPOOL JOHN LENNON<br />
              AIRPORT PARKING
            </h2>
            
            {/* Airport Image */}
            <div className="mt-8 mb-12 relative rounded-lg overflow-hidden shadow-lg">
              <img 
                src="https://img-assets.bookfhr.com/liverpool-airport-1-2-35-1562578953-34-1706013787.png"
                alt="Liverpool John Lennon Airport Parking" 
                className="w-full h-auto"
              />
            </div>

            <div className="space-y-8">
              <p className="text-lg leading-relaxed text-gray-600">
                With outdoor parking, plus the option for Meet & Greet, this car park is located 
                just 500 meters away from Liverpool airport and is easily walkable. Any airport 
                entry fees apply to customers.
              </p>
              
              <p className="text-lg leading-relaxed text-gray-600">
                Hassle-free, travel in confidence - 24 hour Security Patrols and CCTV. Pick up 
                from the same Drop-off Point on Return - Full instructions are given with 
                Booking Confirmation. We apply a charge of 25%, at arrival, for any commercial 
                vehicles, minibuses or vans.
              </p>
            </div>

            {/* Key Features */}
            <div className="mt-16 grid md:grid-cols-2 gap-8">
              <div className="space-y-4">
                <h3 className="text-xl font-medium text-gray-800">Security Features</h3>
                <ul className="space-y-2 text-gray-600">
                  <li className="flex items-center">
                    <span className="text-blue-600 mr-2">•</span>
                    24/7 Security Patrols
                  </li>
                  <li className="flex items-center">
                    <span className="text-blue-600 mr-2">•</span>
                    CCTV Monitoring
                  </li>
                  <li className="flex items-center">
                    <span className="text-blue-600 mr-2">•</span>
                    Secure Fencing
                  </li>
                </ul>
              </div>
              
              <div className="space-y-4">
                <h3 className="text-xl font-medium text-gray-800">Service Features</h3>
                <ul className="space-y-2 text-gray-600">
                  <li className="flex items-center">
                    <span className="text-blue-600 mr-2">•</span>
                    500m from Airport
                  </li>
                  <li className="flex items-center">
                    <span className="text-blue-600 mr-2">•</span>
                    Meet & Greet Available
                  </li>
                  <li className="flex items-center">
                    <span className="text-blue-600 mr-2">•</span>
                    Same Drop-off/Pick-up Point
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs; 