import React from 'react';

const ServiceCard = ({ icon, title, description }) => (
  <div className="bg-white p-8 flex flex-col items-center text-center">
    <div className="text-skypark-blue mb-6">
      {icon}
    </div>
    <h3 className="text-xl font-medium mb-4">{title}</h3>
    <p className="text-gray-600 leading-relaxed">{description}</p>
  </div>
);

const Services = () => {
  return (
    <div className="pt-32 pb-16">
      {/* Header */}
      <div className="max-w-7xl mx-auto px-4">
        <h1 className="text-4xl md:text-5xl font-light text-center mb-8">
          Skypark - Parking Confidence
        </h1>
        <p className="text-center text-lg text-gray-600 max-w-3xl mx-auto mb-16">
          We'd love to take care of your vehicle while you are away and both of our facilities provide a hassle-free, simple to use parking service. Travel with confidence, knowing that we have 24 hour Security Patrols, CCTV and a team of specialists who are here to make the start and end of your journey as simple as possible.
        </p>
      </div>

      {/* Services Grid */}
      <div className="max-w-7xl mx-auto px-4">
        <div className="grid md:grid-cols-2 gap-8">
          <ServiceCard
            icon={
              <img 
                src="https://img-assets.bookfhr.com/star-circled-1-3.svg"
                alt=""
                className="w-16 h-16"
              />
            }
            title="TRUSTED"
            description="Thousands of valued customers have been happy to leave their vehicle in our safe hands."
          />

          <ServiceCard
            icon={
              <img 
                src="https://img-assets.bookfhr.com/smile-1-3.svg"
                alt=""
                className="w-16 h-16"
              />
            }
            title="CUSTOMER SERVICE"
            description="Our customer service is second to none. Available around the clock, our team of experts are always on hand to help."
          />

          <ServiceCard
            icon={
              <img 
                src="https://img-assets.bookfhr.com/back-in-time-1-3.svg"
                alt=""
                className="w-16 h-16"
              />
            }
            title="BOOK 24 HOURS"
            description="You can book whenever is most convenient to you, as we operate a 24 hour booking system. However, bookings do need to be at least 48 hours prior to travelling."
          />

          <ServiceCard
            icon={
              <img 
                src="https://img-assets.bookfhr.com/cc-visa-1-3.svg"
                alt=""
                className="w-16 h-16"
              />
            }
            title="SECURE CARD TRANSACTIONS"
            description="Both our Stripe™ and PayPal™ payment options are fully secure and accept all major credit and debit cards, as well as Apple Pay and Google Pay. Each have one of these set up and are using a mobile device."
          />
        </div>

        {/* Call to Action */}
        <div className="mt-16 text-center">
          <a 
            href="/" 
            className="inline-block bg-skypark-blue text-white px-8 py-3 rounded hover:bg-blue-700 transition-colors duration-300"
          >
            Book Your Space Now
          </a>
        </div>
      </div>
    </div>
  );
};

export default Services; 