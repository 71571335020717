import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const isActivePath = (path) => {
    return location.pathname === path;
  };

  const navLinkClasses = (path) => {
    return `block px-3 py-2 text-sm font-medium transition-all duration-200 rounded ${
      isActivePath(path)
        ? 'text-white bg-[#1e293b] shadow-sm'
        : 'text-gray-700 hover:text-skypark-blue hover:bg-gray-50'
    }`;
  };

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  return (
    <nav 
      className="bg-white shadow-lg fixed w-full z-50" 
      role="navigation" 
      aria-label="Main navigation"
    >
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between h-16">
          {/* Logo */}
          <div className="flex-shrink-0 flex items-center">
            <Link to="/" className="block" aria-label="Skypark - Return to homepage" onClick={handleLinkClick}>
              <img 
                src="https://cdn.enjoytravel.com/img/logos/liverpoolskypark-logo.svg" 
                alt="Skypark Logo" 
                className="h-12 w-auto"
                width="160"
                height="32"
              />
            </Link>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex md:items-center md:space-x-4">
            <Link to="/" className={navLinkClasses('/')} role="menuitem" onClick={handleLinkClick}>HOME</Link>
            {/* <Link to="/reservations" className={navLinkClasses('/reservations')} role="menuitem" onClick={handleLinkClick}>RESERVATIONS</Link> */}
            <Link to="/location" className={navLinkClasses('/location')} role="menuitem" onClick={handleLinkClick}>LOCATION</Link>
            <Link to="/about" className={navLinkClasses('/about')} role="menuitem" onClick={handleLinkClick}>ABOUT US</Link>
            <Link to="/services" className={navLinkClasses('/services')} role="menuitem" onClick={handleLinkClick}>SERVICES</Link>
            <Link to="/faqs" className={navLinkClasses('/faqs')} role="menuitem" onClick={handleLinkClick}>FAQS</Link>
            <Link to="/commercial-vehicles" className={navLinkClasses('/commercial-vehicles')} role="menuitem" onClick={handleLinkClick}>COMMERCIAL VEHICLES</Link>
            <Link to="/contact" className={navLinkClasses('/contact')} role="menuitem" onClick={handleLinkClick}>CONTACT US</Link>
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden flex items-center">
            <button
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-700 hover:text-skypark-blue hover:bg-gray-100 focus:outline-none"
              onClick={() => setIsOpen(!isOpen)}
              aria-expanded={isOpen}
              aria-label="Toggle navigation menu"
            >
              <svg
                className="h-6 w-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Navigation */}
      <div 
        className={`md:hidden transform transition-all duration-300 ease-in-out ${
          isOpen 
            ? 'opacity-100 max-h-96 translate-y-0' 
            : 'opacity-0 max-h-0 -translate-y-2 overflow-hidden'
        }`}
      >
        <div className="px-2 pt-2 pb-3 space-y-1">
          <Link to="/" className={navLinkClasses('/')} role="menuitem" onClick={handleLinkClick}>HOME</Link>
          {/* <Link to="/reservations" className={navLinkClasses('/reservations')} role="menuitem" onClick={handleLinkClick}>RESERVATIONS</Link> */}
          <Link to="/location" className={navLinkClasses('/location')} role="menuitem" onClick={handleLinkClick}>LOCATION</Link>
          <Link to="/about" className={navLinkClasses('/about')} role="menuitem" onClick={handleLinkClick}>ABOUT US</Link>
          <Link to="/services" className={navLinkClasses('/services')} role="menuitem" onClick={handleLinkClick}>SERVICES</Link>
          <Link to="/faqs" className={navLinkClasses('/faqs')} role="menuitem" onClick={handleLinkClick}>FAQS</Link>
          <Link to="/contact" className={navLinkClasses('/contact')} role="menuitem" onClick={handleLinkClick}>CONTACT US</Link>
          <Link to="/commercial-vehicles" className={navLinkClasses('/commercial-vehicles')} role="menuitem" onClick={handleLinkClick}>Commercial Vehicles</Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar; 