import React, { useState, useEffect, useRef } from 'react';

const Location = () => {
  const [showDirections, setShowDirections] = useState(false);
  const [origin, setOrigin] = useState('');
  const mapRef = useRef(null);
  const autocompleteInput = useRef(null);
  const SKYPARK_LOCATION = "Skypark Ltd, 25 Owen Dr, Speke Liverpool L24 1YL UK";
  
  // Initialiser la carte
  useEffect(() => {
    if (window.google && mapRef.current) {
      // Définir les coordonnées à l'intérieur du useEffect
      const center = {
        lat: 53.340775,
        lng: -2.860689
      };
      
      const map = new window.google.maps.Map(mapRef.current, {
        center,
        zoom: 12,
        mapTypeId: window.google.maps.MapTypeId.ROADMAP
      });
      
      // Ajouter un marqueur
      new window.google.maps.Marker({
        position: center,
        map,
        title: "Skypark Location"
      });
    }
  }, []); // Plus besoin de dépendance center

  // Initialiser l'autocomplete
  useEffect(() => {
    if (showDirections && window.google && autocompleteInput.current) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        autocompleteInput.current,
        { 
          componentRestrictions: { country: "uk" },
          fields: ["formatted_address"],
          types: ["geocode"]
        }
      );

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (place.formatted_address) {
          setOrigin(place.formatted_address);
        }
      });
    }
  }, [showDirections]);

  const handleDirectionsClick = () => {
    if (origin) {
      window.open(
        `https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(origin)}&destination=${encodeURIComponent(SKYPARK_LOCATION)}`,
        '_blank'
      );
    }
  };

  return (
    <div className="pt-32 pb-16">
      {/* Header */}
      <div className="max-w-7xl mx-auto px-4">
        <h1 className="text-4xl md:text-5xl font-light text-center mb-8">Location</h1>
        <p className="text-center text-lg text-gray-600 max-w-2xl mx-auto mb-16">
          You'll find a map provided below that will guide you to our location,
          ensuring you can locate us without any trouble.
        </p>
      </div>

      {/* Map and Contact Info Section */}
      <div className="max-w-7xl mx-auto px-4">
        <div className="grid md:grid-cols-4 gap-8">
          {/* Map - Takes up 3 columns */}
          <div className="md:col-span-3 h-[500px] relative rounded overflow-hidden">
            <div 
              ref={mapRef} 
              className="w-full h-full"
            />
            
            <div className="absolute bottom-4 right-4 bg-white py-2 px-4 shadow-md rounded flex items-center space-x-2">
              <div className="w-2 h-2 bg-red-500 rounded-full"></div>
              <span className="text-sm font-medium">Skypark Location</span>
            </div>
          </div>

          {/* Contact Info Card */}
          <div className="bg-white shadow">
            <div>
              {/* Location Image */}
              <img
                src="https://img-assets.bookfhr.com/liverpool-airport-1-2-35-1562578953-34-1706013787.png"
                alt="Skypark Location"
                className="w-full h-32 object-cover"
              />

              <div className="p-6">
                <h2 className="text-2xl mb-6">Liverpool</h2>
                
                <div className="space-y-4">
                  <div>
                    <h3 className="text-gray-600 text-sm">Phone:</h3>
                    <a 
                      href="tel:01514480300" 
                      className="text-skypark-blue hover:underline"
                    >
                      0151 448 0300
                    </a>
                  </div>

                  <div>
                    <h3 className="text-gray-600 text-sm">Email:</h3>
                    <a 
                      href="mailto:finance@skypark.co.uk" 
                      className="text-skypark-blue hover:underline"
                    >
                      finance@skypark.co.uk
                    </a>
                  </div>

                  <div>
                    <h3 className="text-gray-600 text-sm">Address:</h3>
                    <p className="text-gray-800">
                      Skypark Ltd, 25 Owen Dr<br />
                      Speke Liverpool L24 1YL<br />
                      UK
                    </p>
                  </div>
                </div>

                {/* Action Buttons */}
                <div className="space-y-2 mt-6">
                  <button className="w-full bg-gray-50 text-gray-700 py-2 px-4 hover:bg-gray-100 transition-colors duration-300 text-sm">
                    Show working time
                  </button>
                  
                  <button 
                    onClick={() => setShowDirections(!showDirections)}
                    className="w-full bg-gray-50 text-gray-700 py-2 px-4 hover:bg-gray-100 transition-colors duration-300 text-sm flex items-center justify-between"
                  >
                    <span>Directions</span>
                    <svg 
                      className={`w-4 h-4 transform transition-transform ${showDirections ? 'rotate-180' : ''}`} 
                      fill="none" 
                      stroke="currentColor" 
                      viewBox="0 0 24 24"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                    </svg>
                  </button>
                  
                  {showDirections && (
                    <div className="mt-2 p-4 bg-gray-50 rounded-sm space-y-3">
                      <div className="space-y-2">
                        <label htmlFor="origin" className="text-sm text-gray-600 block">
                          Starting point:
                        </label>
                        <input
                          ref={autocompleteInput}
                          id="origin"
                          type="text"
                          placeholder="Enter your location"
                          className="w-full border border-gray-200 p-2 text-sm rounded-sm focus:ring-1 focus:ring-skypark-blue focus:border-skypark-blue outline-none"
                          value={origin}
                          onChange={(e) => setOrigin(e.target.value)}
                        />
                      </div>
                      <div className="flex items-center space-x-2 text-sm text-gray-500">
                        <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
                        </svg>
                        <span>Skypark Ltd, 25 Owen Dr</span>
                      </div>
                      <button 
                        onClick={handleDirectionsClick}
                        disabled={!origin}
                        className={`w-full py-2 px-4 text-sm rounded-sm transition-colors duration-300
                          ${origin 
                            ? 'bg-skypark-blue text-white hover:bg-blue-700' 
                            : 'bg-gray-200 text-gray-500 cursor-not-allowed'}`}
                      >
                        Get Directions
                      </button>
                    </div>
                  )}
                  
                  <button 
                  className="w-full bg-skypark-blue text-white py-2 px-4 hover:bg-blue-700 transition-colors duration-300 text-sm">
                  <a href="/">
                    Book now
                  </a>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Location; 