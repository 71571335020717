import React from 'react';

const StepIndicator = ({ number, title, description }) => (
  <div className="flex flex-col items-center text-center mb-8 md:mb-0">
    <div className="w-12 h-12 rounded-full bg-skypark-blue text-white flex items-center justify-center text-xl font-bold mb-4">
      {number}
    </div>
    <h3 className="text-lg font-medium mb-2">{title}</h3>
    <p className="text-gray-600 max-w-sm">{description}</p>
  </div>
);

const Reservations = () => {
  return (
    <div className="pt-32 pb-16">
      {/* Header */}
      <div className="max-w-7xl mx-auto px-4">
        <h1 className="text-4xl md:text-5xl font-light text-center mb-8">
          Reservations
        </h1>
        <div className="max-w-4xl mx-auto">
          {/* Steps */}
          <div className="grid md:grid-cols-2 gap-12 mb-16">
            <StepIndicator 
              number="1"
              title="Select the dates for your stay"
              description="Choose your entry and exit dates, along with the corresponding times for your parking needs."
            />
            <StepIndicator 
              number="2"
              title="Choose parking space type"
              description="Select between Meet & Greet or Outdoor parking options to best suit your requirements."
            />
          </div>

          {/* Widget Placeholder */}
          <div className="bg-black h-[400px] rounded-sm flex items-center justify-center">
            <p className="text-white text-lg">Booking Widget Coming Soon</p>
          </div>

          {/* Additional Info */}
          <div className="mt-8 text-center text-gray-600">
            <p>It's as easy as that!</p>
            <p className="mt-2">
              Can't find the location?{' '}
              <a href="/location" className="text-skypark-blue hover:underline">
                View Map
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reservations; 