import React from 'react';

const Terms = () => {
  return (
    <div className="pt-32 pb-16">
      <div className="max-w-4xl mx-auto px-4">
        <h1 className="text-4xl font-bold mb-8">Terms & Conditions</h1>
        <p className="text-gray-600 mb-8">Terms as at 28th of July 2023</p>

        <div className="prose max-w-none space-y-8">
          {/* About Us Section */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">About us</h2>
            <p className="mb-6">
              These terms and conditions are governed by English law and apply to all booking for service made via our website https://www.enjoytravel.com/. Any dispute between us and you will be resolved exclusively in the courts of England. In these terms references to "we" "our" or "us" are references to Enjoy Travel Technology Limited a company registered in England and Wales with company number 13054678, whose registered address is Unit 7 Borers Yard, Borers Arms Road Copthorne, Crawley, West Sussex, RH10 3LH, UK. Our Customer Service team can be contracted on customerservices@bookfhr.com.
            </p>
            <div className="bg-gray-50 p-4 rounded-lg mb-6">
              <p className="font-medium">Please note your payment will be processed by FHR Airport Services.</p>
            </div>
          </section>

          {/* Prices Section */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">Prices</h2>
            <p className="mb-6">
              All prices are for pre-booking and include VAT and service charges. Hotel prices are given in pounds sterling per room per night. Parking prices are given in pound sterling per vehicle per whole or part day. Car hire charges are given on a per day or part day basis. Airport lounge charges are given on a per day or part day basis. We reserve the right to correct any pricing errors on our website and/or on any booking at an incorrect price.
            </p>
          </section>

          {/* Services Section */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">Services</h2>
            <p className="mb-4">All services are subject to availability.</p>
            <p className="mb-6">
              These are our general booking terms and conditions. The companies that provide the services will have their own terms and conditions which will apply to you. With regard to all services, we act as an agent for the service provider (for example hotel or car park operator) and your contact for services is with that service provider. Please check the individual hotel or car park information pages for any specific terms that may apply to your booking.
            </p>
            <p>
              We will send you a booking confirmation / voucher. If for any reason your confirmation or voucher has not been received you must contact us immediately as no refund is available for no shows. We are not liable to you if you do not follow the instructions we give you on your booking confirmation.
            </p>
          </section>

          {/* Cancellation Section */}
          <section className="bg-gray-50 p-6 rounded-lg">
            <h2 className="text-2xl font-semibold mb-6">1. Cancellation & Amendment Charges and Refunds</h2>
            <p className="mb-4 font-medium">Please note these do not apply to Car Hire Services</p>

            <div className="overflow-x-auto">
              <table className="w-full mb-6">
                <thead>
                  <tr className="border-b">
                    <th className="text-left py-2 px-4">Cancellation timeframe</th>
                    <th className="text-left py-2 px-4">Charge</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-b">
                    <td className="py-2 px-4">Less than 48 Hours' Notice of date of stay</td>
                    <td className="py-2 px-4">Cancellation Charge: Full Cost: No Refund and No Amendments</td>
                  </tr>
                  <tr className="border-b">
                    <td className="py-2 px-4">More than 48 Hours' Notice</td>
                    <td className="py-2 px-4">Cancellation Charge: Unless a cancellation fee waiver has been paid, there will be a GBP 10.00 cancellation fee, with the balance refunded*</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="bg-white p-4 rounded mb-6">
              <p className="font-medium mb-2">During September and December 2023 – Free Cancellation Waiver is available on all bookings (excluding non-refundable products)</p>
              
              <table className="w-full mb-4">
                <thead>
                  <tr className="border-b">
                    <th className="text-left py-2 px-4">Cancellation timeframe (September and October 2023)</th>
                    <th className="text-left py-2 px-4">Charge (September and December 2023)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-b">
                    <td className="py-2 px-4">Less than 24 Hours' Notice before the day of travel.</td>
                    <td className="py-2 px-4">Cancellation Charge: Full Cost: No Refund and No Amendments</td>
                  </tr>
                  <tr className="border-b">
                    <td className="py-2 px-4">More than 24 Hours' Notice before the day of travel.</td>
                    <td className="py-2 px-4">Cancellation Charge: Cancellation can be made and you will receive a full refund.</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <p className="text-sm italic">Where applicable, credit card or transaction fees are non-refundable.</p>
          </section>

          {/* Exceptions Section */}
          <section className="bg-white p-6 rounded-lg border border-gray-200">
            <h3 className="text-xl font-semibold mb-4">*Please note the following exceptions:</h3>
            
            <div className="space-y-6">
              <div>
                <h4 className="font-medium mb-2">Airport Hotels</h4>
                <p>Except on certain promotional offers which are non-refundable. Where applicable this will be stated in the hotel description.</p>
              </div>

              <div>
                <h4 className="font-medium mb-2">Parking Bookings</h4>
                <p className="mb-4">Certain promotional offers, Advance Purchase and Super Saver options are non-refundable. Where applicable this will be stated in the car park description/information. Where applicable, credit card or transaction fees are non-refundable.</p>
                <p className="mb-4">If you do not arrive on the date booked to park your car then your booking will be forfeited.</p>
                <p>If your return is delayed and you have been away for longer than you have booked, you will need to pay the difference on return, direct to the car park, at their standard daily rate. No refund is available for unused part stays.</p>
              </div>

              <div>
                <h4 className="font-medium mb-2">Lounge Bookings</h4>
                <p>No refund is available for unused part stays.</p>
              </div>

              <div>
                <h4 className="font-medium mb-2">Car Hire</h4>
                <p>All cancellations must be received in writing 48 hours before you are due to collect your vehicle. If this has been done then there is no charge for this service. If notification has not been received until after this time, then a 50% cancellation charge will apply. There are no amendment fees.</p>
              </div>
            </div>
          </section>

          {/* Hotels Section */}
          <section>
            <h2 className="text-2xl font-semibold mb-6">2. Hotels</h2>
            
            <div className="space-y-6">
              <div>
                <h3 className="text-lg font-medium mb-2">2.1 Directions</h3>
                <p>2.1.1 Please make sure that you have directions to your hotel.</p>
              </div>

              <div>
                <h3 className="text-lg font-medium mb-2">2.2 Transfers to and from the airport</h3>
                <p>2.2.1 Transfers times are different at each hotel. All transfers are subject to availability on the day and may be subject to a local charge. Please check hotel information pages for details and charges. Prices and information regarding the transfers to and from the hotel are given as a guide only and may be subject to change.</p>
              </div>

              <div>
                <h3 className="text-lg font-medium mb-2">2.3 Our Star Ratings</h3>
                <p>2.3.1 All hotel star ratings in all our literature and on our website are our own ratings.</p>
              </div>

              <div>
                <h3 className="text-lg font-medium mb-2">2.4 Facilities</h3>
                <p>2.4.1 All hotel rooms have bath/shower room, a colour TV and tea/coffee making facilities.</p>
              </div>

              <div>
                <h3 className="text-lg font-medium mb-2">2.5 Room Requests</h3>
                <p>2.5.1 If you have any room request, you must advise us at the time of booking. Although we will endeavour to pass any reasonable requests on to the relevant supplier, we regret we cannot guarantee any request will be met. Failure to meet any room request will not be a breach of contract on our part. Confirmation that a room request has been noted or passed on to the supplier or the inclusion of the room request on your confirmation invoice or any other documentation is not confirmation that the request will be met. Unless and until specifically confirmed, all room requests are subject to availability.</p>
              </div>

              {/* Meals and Facilities Sections */}
              <div>
                <h3 className="text-lg font-medium mb-2">2.6 Meals</h3>
                <p>2.6.1 Unless specified meals are not included in the hotel booking, all extras must be paid direct to supplier. If breakfast is included, please check with the hotel the time it is available. If breakfast is not taken where included there will be no refund.</p>
              </div>

              <div>
                <h3 className="text-lg font-medium mb-2">2.7 Leisure Facilities</h3>
                <p>2.7.1 There may be charges for leisure facilities and admission may be restricted for children.</p>
              </div>

              <div>
                <h3 className="text-lg font-medium mb-2">2.8 Hotels including Holiday Parking</h3>
                <p className="mb-4">2.8.1 Parking is always at the vehicle owner's risk. Car parks are unsecured unless otherwise stated. Car parking arrangements vary from hotel to hotel. Please make sure you know exactly what your arrangements are before you travel. Where parking is offered as an 8, 15 or 21 day option, the full number of days does not have to be used, but there is no reduction in price if it is not. If you leave your car for longer than the duration included, the hotel or car park can charge you for the extra days.</p>
                <p>2.8.2 In the interests of efficient operation you must be prepared to leave your car keys with the hotel or car park if requested to do so.</p>
              </div>

              <div>
                <h3 className="text-lg font-medium mb-2">2.9 Cancellation</h3>
                <p>2.9.1 No refund is made for cancellation of hotel bookings within 48 hours of the date of stay. Unless a cancellation fee waiver has been paid, the amount paid will be held as a credit on your booking for use in the future except on certain promotional offers which are non-refundable. Where applicable this will be stated in the hotel description/information.</p>
              </div>
            </div>
          </section>

          {/* Airport Parking Section */}
          <section>
            <h2 className="text-2xl font-semibold mb-6">3. Secured Airport Parking and Meet & Greet</h2>
            
            <div className="space-y-6">
              <div>
                <h3 className="text-lg font-medium mb-2">3.1 Directions</h3>
                <p>3.1.1 Please make sure that you have directions to your car park.</p>
              </div>

              <div>
                <h3 className="text-lg font-medium mb-2">3.2 Drop-off time</h3>
                <p>3.2.1 We and the individual car park company cannot be held responsible if you do not leave enough time to drop off your car at the car park, or for airport transfer/car collection before your flight.</p>
              </div>

              <div>
                <h3 className="text-lg font-medium mb-2">3.3 Meet and greet bookings</h3>
                <p>3.3.1 The 'parking from' time on your confirmation is the time slot the car park has allocated you. If you think you are going to be more than 30 minutes earlier or later than your booked time, you must call the chauffeur at least 30 minutes prior to the time booked. We will then endeavour to re-book a new arrival time for you. If you do not call us and arrive after the time booked, we cannot guarantee a driver will be available to meet you.</p>
              </div>

              <div>
                <h3 className="text-lg font-medium mb-2">3.4 Transfers</h3>
                <p>3.4.1 24 Hour transfers to and from the airport are included unless otherwise stated on the individual car park information pages.</p>
              </div>

              <div>
                <h3 className="text-lg font-medium mb-2">3.5 Minimum Stays</h3>
                <p>3.5.1 A minimum stay applies at some car parks. Should you wish to stay for fewer days, you can, but the cost of the minimum duration is payable.</p>
              </div>

              <div>
                <h3 className="text-lg font-medium mb-2">3.6 Car Keys</h3>
                <p>3.6.1 Unless stated on the car park information page, in the interests of efficient operation you must be prepared to leave your car keys with the car park. The car parks will not accept liability for faulty keys, alarm fobs, house or other keys left on the key ring. In the event of vehicles not starting, we reserve the right to charge for our time. We advise that only the car key is given and that you ensure you have a spare key which you take with you.</p>
              </div>
            </div>
          </section>

          {/* More Parking Sections */}
          <section className="space-y-6">
            <div>
              <h3 className="text-lg font-medium mb-2">3.7 Vans and Large Vehicles</h3>
              <p>3.7.1 Unless otherwise stated, we only accept bookings for cars and small vans (in both cases without trailers) at the standard rate shown. Please contact us before you make your booking if you wish to book larger vehicles, or vehicles with trailers, for a quote. If you park a vehicle larger than a car or small van, or a vehicle with a trailer in a car park without prior approval, you will likely incur an additional charge at the car park.</p>
            </div>

            <div>
              <h3 className="text-lg font-medium mb-2">3.8 Cancellation</h3>
              <p>3.8.1 You cannot get a refund or amend a parking booking less than 48 hours before the day of travel. For example, your last opportunity to change your package if you're flying at 3pm on the 29th will be 23:59 on the 26th. Unless a cancellation fee waiver has been paid, the amount paid will be held as a credit on your booking for use in the future except on certain promotional offers which are non-refundable. Where applicable this will be stated in the car park description/information.</p>
            </div>

            <div>
              <h3 className="text-lg font-medium mb-2">3.9 Insurance & Liability</h3>
              <p>3.9.1 Ensure no valuables are left in the vehicle, we and the car park will not accept liability. Parking is always at the vehicle owner's risk. In the unlikely event that you need to make a claim for loss or damage, you MUST notify the car park at time of collection and obtain written confirmation, as claims cannot be considered once your vehicle has left the site. The car parks will accept liability for proven acts of their negligence.</p>
            </div>

            <div>
              <h3 className="text-lg font-medium mb-2">3.10 Valuables</h3>
              <p>3.10.1 Valuables SHOULD NOT be left in the vehicle. All vehicles and contents are left at owners risk.</p>
            </div>

            <div>
              <h3 className="text-lg font-medium mb-2">3.11 Your Vehicle</h3>
              <p>3.11.1 It is the responsibility of the vehicle owner to ensure that the vehicle is roadworthy, holds a valid MOT certificate and your vehicle is taxed until your return date as your booking may be forfeited if this criteria is not met. The car parks will not accept responsibility for mechanical, structural and electrical failure of any part of your vehicle including windscreens, glass, tyres and in particular alloy wheels howsoever caused. This list is not exhaustive.</p>
            </div>
          </section>

          {/* Airport Lounges Section */}
          <section>
            <h2 className="text-2xl font-semibold mb-6">4. Airport Lounges</h2>
            
            <div className="space-y-6">
              <div>
                <h3 className="text-lg font-medium mb-2">4.1 Invitations</h3>
                <p>4.1.1 Please ensure that you have the correct invitations for the lounge and terminal you require. You must print and take the invitations with you to the lounge as you may be refused entry without it.</p>
              </div>

              <div>
                <h3 className="text-lg font-medium mb-2">4.2 Lounge Location</h3>
                <p>4.2.1 Please remember to check the lounge location is accessible from the terminal you are travelling from and that the hours of operation match your travel plans. Please check with your travel provider before travelling. We will not be held liable for any lounge pass holder that has not booked the correct lounge for their flight. We will not refund any unused lounge passes.</p>
              </div>

              <div>
                <h3 className="text-lg font-medium mb-2">4.3 Children</h3>
                <p>4.3.1 Where permitted, children must be accompanied by an adult to the bar areas at all times.</p>
              </div>

              <div>
                <h3 className="text-lg font-medium mb-2">4.4 Dress Code</h3>
                <p>4.4.1 A dress code for the lounge facilities is expected. Singlets, vests, causal shorts, are not acceptable.</p>
              </div>

              <div>
                <h3 className="text-lg font-medium mb-2">4.5 Groups</h3>
                <p>4.5.1 Any groups that have not been pre-booked in accordance with our group booking policy will be refused entry to the lounge and no refund offered.</p>
              </div>

              {/* Lounge Rules Continued */}
              <div>
                <h3 className="text-lg font-medium mb-2">4.6 Alcohol</h3>
                <p>4.6.1 Due to regulatory restrictions, consumables are not to be taken from the lounge. We would like to advise passengers it is illegal to travel on an aircraft under the influence of alcohol. We will not accept any responsibility for passengers who are refused travel due to being intoxicated.</p>
              </div>

              <div>
                <h3 className="text-lg font-medium mb-2">4.7 Admittance</h3>
                <p>4.7.1 The lounges reserve the right to refuse admittance to unsuitably dressed passengers or any passenger who is under the influence of alcohol.</p>
              </div>

              <div>
                <h3 className="text-lg font-medium mb-2">4.8 Flights</h3>
                <p>4.8.1 It is your responsibility to ensure that you are at the aircraft boarding gate on time. We are not liable for any flight missed or delayed by any passenger using the lounge.</p>
              </div>

              <div>
                <h3 className="text-lg font-medium mb-2">4.9 Cancellation</h3>
                <p>4.9.1 You cannot get a refund or amend a lounge booking less than 48 hours before the day of travel. For example, your last opportunity to change your booking if you're flying at 3pm on the 29th will be 23:59 on the 26th. Unless a cancellation fee waiver has been paid, the amount paid will be held as a credit on your booking for use in the future, except on certain promotional offers which are non-refundable. Where applicable this will be stated in the description/information.</p>
              </div>
            </div>
          </section>

          {/* Final Sections */}
          <section className="space-y-8">
            <div>
              <h2 className="text-2xl font-semibold mb-4">5. Travel Insurance</h2>
              <p>5.1 For full policy details you must read your policy document carefully. You have a 14 day 'cooling off' period from when you took out your policy, in which you can return documents and obtain a refund if you have a justifiable reason to be dissatisfied with the cover.</p>
            </div>

            <div>
              <h2 className="text-2xl font-semibold mb-4">6. Car Hire</h2>
              <p>6.1 For full policy details you must read your policy document carefully.</p>
            </div>

            <div>
              <h2 className="text-2xl font-semibold mb-4">7. Best Price Guarantee</h2>
              <p>7.1 Our Best Price guarantee applies to Airport Parking only:</p>
              
              <div className="ml-4 mt-4 space-y-2">
                <p>7.2 For a claim to be successful:</p>
                <p>7.2.1 The parking, package of products that you are comparing must be the same in every respect as that which you purchased from us;</p>
                <p>7.2.2 You must make your claim within 24 hours of making and paying for your booking with us;</p>
                <p>7.2.3 You must use the products or package of products as per the booking against which you are making your claim; and</p>
                <p>7.2.4 Comparison prices must be listed and quoted in pounds sterling.</p>
              </div>

              <p className="mt-4">7.3 Successful claims will not be paid until after the return date originally booked.</p>
              <p>7.4 Cancellation of the booking cancels your right to claim under this guarantee.</p>
              
              <div className="mt-4">
                <p>7.5 Our Best price guarantee and price comparison excludes:</p>
                <div className="ml-4 mt-2 space-y-2">
                  <p>7.5.1 Prices from other suppliers that are conditional on buying other products;</p>
                  <p>7.5.2 Prices that are part of a customer loyalty, members or loyalty reward scheme;</p>
                  <p>7.5.3 Prices that are part of a staff discount scheme; and</p>
                  <p>7.5.4 Prices that are part of any other discount or special offer deal.</p>
                </div>
              </div>
            </div>

            <div>
              <h2 className="text-2xl font-semibold mb-4">8. Events beyond our control</h2>
              <div className="space-y-4">
                <p>8.1 Very rarely, we may be forced to change or terminate your booking due to unforeseen major events known as force majeure. This means any major event which we or the supplier of the service(s) in question could not, even with all due care, foresee or avoid and that was beyond our reasonable control. For the avoidance of doubt events beyond our control include (but are not limited to), war or the threat of war, riots, civil unrest, terrorist threats or activity, industrial disputes, extreme weather, epidemics, natural and nuclear disaster, fire, adverse weather conditions, government regulations and advice, airlines cancelling or changing schedules due to airports or airways being congested or closed.</p>
                <p>8.2 Changes or termination of your contract due to events beyond our control are extremely unlikely. If, however, such major events do occur, we regret we will be unable to make any refund of payments for services not received. We cannot accept liability or pay any compensation where the performance (or prompt performance) of our obligations under our contract with you is prevented or affected by events beyond our control. This is also the case if you suffer any damage, loss or expense of any nature as a result of the major event.</p>
                <p>8.3 Your statutory rights as a consumer are not affected.</p>
              </div>
            </div>

            <div>
              <h2 className="text-2xl font-semibold mb-4">9. Errors & Omissions Excepted</h2>
              <p>9.1 We reserve the right to correct errors prior to confirming your booking. You must ensure you check all details of your chosen booking (including the price) with us at the time of booking.</p>
            </div>

            <div>
              <h2 className="text-2xl font-semibold mb-4">10. Liability</h2>
              <div className="space-y-4">
                <p>10.1 Nothing in these terms and conditions will limit our liability in the case of death or personal injury caused by our negligence or in the case of fraud.</p>
                <p>10.2 Our liability to you shall be limited to the commission we receive from the service provider (for example hotel or car park operator) for the services you have purchased.</p>
                <p>10.3 We do not accept liability for: any indirect or consequential loss; any loss of profit; loss of enjoyment; loss of revenue; loss of data; loss of earnings; or costs arising from your missed flights, connection, or other services you have prebooked.</p>
              </div>
            </div>

            <div>
              <h2 className="text-2xl font-semibold mb-4">11. Privacy</h2>
              <p>11.1 To find out more about how we use your data, please take a look at our Privacy policy</p>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Terms; 