import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-[#2C3338] text-white" itemScope itemType="https://schema.org/Organization">
      {/* Main Footer Content */}
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Logo Column */}
          <div>
            <Link 
              to="/" 
              className="block" 
              aria-label="Skypark - Return to homepage"
            >
              <img 
                src="https://cdn.enjoytravel.com/img/logos/liverpoolskypark-logo.svg" 
                alt="Skypark Logo" 
                className="h-12 w-auto"
                width="160"
                height="32"
              />
            </Link>
          </div>

          {/* Contact Info Column */}
          <div className="space-y-4">
            {/* Phone */}
            <div className="flex items-center space-x-2">
              <img 
                src="https://img-assets.bookfhr.com/phone-49-1550068927.png"
                alt=""
                className="w-7 h-7 opacity-80"
                aria-hidden="true"
              />
              <span className="text-white opacity-60 text-sm">Call Us Now</span>
              <a 
                href="tel:+441514480300" 
                className="text-sm text-white opacity-80 hover:opacity-100 transition-opacity"
                itemProp="telephone"
                aria-label="Call us on +44 (0)151 448 0300"
              >
                +44 (0)151 448 0300
              </a>
            </div>

            {/* Email */}
            <div className="flex items-center space-x-2">
              <svg 
                className="w-7 h-7 text-white opacity-80" 
                fill="currentColor" 
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"/>
              </svg>
              <span className="text-white opacity-60 text-sm">Or send us a message</span>
              <a 
                href="mailto:finance@skypark.co.uk" 
                className="text-sm text-white opacity-80 hover:opacity-100 transition-opacity"
                itemProp="email"
                aria-label="Email us at finance@skypark.co.uk"
              >
                finance@skypark.co.uk
              </a>
            </div>
          </div>

          {/* Legal Links Column */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Legal Information</h3>
            <ul className="space-y-2">
              <li>
                <Link 
                  to="/terms-and-conditions" 
                  className="text-gray-300 hover:text-white transition-colors flex items-center space-x-2"
                >
                  <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"/>
                    <path fillRule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clipRule="evenodd"/>
                  </svg>
                  <span>Terms & Conditions</span>
                </Link>
              </li>
              <li>
                <Link 
                  to="/privacy-notice" 
                  className="text-gray-300 hover:text-white transition-colors flex items-center space-x-2"
                >
                  <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z" clipRule="evenodd"/>
                    <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z"/>
                  </svg>
                  <span>Privacy Notice</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Copyright Bar */}
      <div className="border-t border-gray-700">
        <div className="max-w-7xl mx-auto px-4 py-4">
          <p className="text-xs text-gray-400 text-center">
            © {new Date().getFullYear()} Skypark Limited Company Registration No. 05207778 All rights reserved.
          </p>
        </div>
      </div>

      {/* Schema.org metadata */}
      <meta itemProp="name" content="Skypark" />
      <meta itemProp="legalName" content="Skypark Limited" />
      <meta itemProp="foundingDate" content="2004" />
      <meta itemProp="vatID" content="05207778" />
      <meta itemProp="url" content="https://www.skypark.co.uk" />
    </footer>
  );
};

export default Footer; 