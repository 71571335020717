import React from 'react';

const Privacy = () => {
  return (
    <div className="pt-32 pb-16">
    <div class="max-w-4xl mx-auto p-6">
    <header class="mb-8">
      <h1 class="text-3xl font-bold mb-4">Privacy Policy</h1>
      <p class="text-sm text-gray-600">
        Our Privacy Policy for Enjoy Travel Technology Limited trading as Enjoy Car Hire and EnjoyTravel.com
      </p>
    </header>

    <article class="bg-white p-6 rounded shadow">
      <section class="mb-6">
        <p class="mb-4">
          We are Enjoy Travel Technology Limited trading as Enjoy Car Hire and EnjoyTravel.com, a company registered in England. Our company number is 13054678 and our registered office is at Unit 7 Borers Yard, Borers Arms Road Copthorne, Crawley, West Sussex, RH10 3LH, UK (“we”, “our” or “us”). We are committed to ensuring that your privacy is protected. We will continue to comply with the provisions of the General Data Protection Regulation ((EU) 2016/679) (“GDPR”) unless and until the GDPR is no longer directly applicable in the UK, together with any national implementing laws, regulations and secondary legislation as amended or updated from time to time in the UK including the Data Protection Act 2018 (“DPA”), and any successor legislation to the GDPR and the DPA (together “Data Protection Legislation”). We are the data controller of data you pass to us pursuant to this policy.
        </p>
        <p class="mb-4">
          This privacy policy together with our website terms and conditions and cookie policy set out how we collect personal information from you and how the personal information you provide will be processed by us. By visiting the website at <a href="https://enjoycarhire.com" class="text-blue-600 underline" target="_blank" rel="noreferrer">enjoycarhire.com</a> (the “Website”) you are accepting and consenting to the practices described in this privacy policy. If you do not consent, please do not submit any personal data to us. We do not require a formally appointed data protection officer as we do not meet the criteria required by the Information Commissioner's Office (“ICO”). However, if you have any questions or comments in respect of our compliance with the Data Protection Legislation, please contact us at <a href="mailto:web@enjoycarhire.com" class="text-blue-600 underline">web@enjoycarhire.com</a>.
        </p>
        <p class="mb-4">
          As a car hire search engine, we connect you with services relating to the hiring of cars offered by third parties (the “Services”). This involves sharing personal information with third party rental car providers (the “Rental Provider”).
        </p>
      </section>

      <section class="mb-6">
        <h2 class="text-2xl font-semibold mb-4">What information do we hold and how will we use it?</h2>
        <p class="mb-4">
          We may collect and process the following data about you:
        </p>
        <ul class="list-disc pl-5 mb-4">
          <li class="mb-2">
            <strong>Information you give us:</strong> You may give us information about you by completing enquiry forms on the Website. The information you give us may include your name, email address and phone number. We will retain this information while we are corresponding with you or fulfilling an order you have placed with us and then for a further period of 2 years;
          </li>
          <li class="mb-2">
            <strong>Information we collect about you:</strong> We may collect the following information from you when you visit our Website: your name, email address, phone number, address, flight number (if applicable), payment details and any other information you directly supply to us when you visit the Website or use our Services; technical information, including the Internet protocol (IP) address used to connect your computer to the Internet, your login information, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform; and information about your visit, including the full Uniform Resource Locators (URL), clickstream to, through and from the Site (including date and time), products you viewed or searched for, page response times, Website errors, length of visits to certain pages, page interaction information, methods used to browse away from the page and any phone number used to call our customer services number. We will retain this information for 2 years.
          </li>
          <li class="mb-2">
            <strong>Information we receive from other sources:</strong> This includes information we receive about you when you use other websites operated by us or other services we provide. This information may include your name, email address, postal address and phone number. We will retain this information for 2 years.
          </li>
        </ul>
      </section>

      <section class="mb-6">
        <h2 class="text-2xl font-semibold mb-4">Cookies</h2>
        <p class="mb-4">
          The Website uses cookies to distinguish you from other users of the Website. For detailed information on the cookies we use and the purposes for which we use them, please see our Cookie Policy.
        </p>
      </section>

      <section class="mb-6">
        <h2 class="text-2xl font-semibold mb-4">Use made of the information</h2>
        <p class="mb-4">
          Provided that we have obtained your consent to the extent we are required to under the Data Protection Legislation, we may use the information we receive and/or collect about you to:
        </p>
        <ul class="list-disc pl-5 mb-4">
          <li class="mb-2">Fulfil our obligations under any contract we have entered into with you and to provide you with information, products and Services you have requested;</li>
          <li class="mb-2">Provide services and information to Rental Providers;</li>
          <li class="mb-2">Send you newsletters and marketing information if you have consented to us doing so or if there are other grounds in GDPR which mean we are entitled to send marketing communications to you;</li>
          <li class="mb-2">Notify you of products and services we offer that are similar to those you have purchased or enquired about;</li>
          <li class="mb-2">Notify you of other products and services we feel may interest you, or permit third parties to do so if you have consented to us doing so;</li>
          <li class="mb-2">Notify you of changes to our services and products;</li>
          <li class="mb-2">Process your payment information;</li>
          <li class="mb-2">Monitor Website usage and provide statistics to third parties for the purposes of improving and developing the Website and the services we provide via the Website.</li>
        </ul>
        <p class="mb-4">
          We collect and process much of your personal information on the grounds of legitimate interests, which include some or all of the following:
        </p>
        <ul class="list-disc pl-5 mb-4">
          <li class="mb-2">Where the processing enables us to enhance, modify, personalise or otherwise improve the Website, our services and communications for the benefit of our customers;</li>
          <li class="mb-2">To identify and prevent fraud;</li>
          <li class="mb-2">To enhance the security of our network and information systems;</li>
          <li class="mb-2">To better understand how people interact with our websites;</li>
          <li class="mb-2">Administer the Website and carry out data analysis, troubleshooting and testing;</li>
          <li class="mb-2">To determine the effectiveness of promotional campaigns and advertising.</li>
        </ul>
        <p class="mb-4">
          If we require your personal data for fulfilment of a contract with you (for example to provide goods and services you have requested), we may be unable to fulfil the contract without your personal data.
        </p>
        <p class="mb-4">
          If we are unable to rely on legitimate interests or any other ground set out in the GDPR to process your personal data, we will obtain consent from you to the processing.
        </p>
        <p class="mb-4">
          You have the right to withdraw your consent to the processing of your personal data at any time. If you would like to withdraw your consent, or prefer not to receive any of the above-mentioned information (or if you only want to receive certain information) from us please let us know by contacting us by emailing <a href="mailto:marketing@enjoytravel.com" class="text-blue-600 underline">marketing@enjoytravel.com</a>. Please bear in mind that if you object this may affect our ability to carry out tasks above for your benefit. Withdrawal of your consent won't affect any processing we have carried out in respect of your personal data prior to you withdrawing consent.
        </p>
        <p class="mb-4">
          If you wish to have your information removed from our database or if you do not want us to contact you for marketing purposes, please let us know by clicking the "Unsubscribe" option in any email we send to you and providing the details requested or by contacting us via the following webpage <a href="https://vandahomes.co.uk" class="text-blue-600 underline" target="_blank" rel="noreferrer">vandahomes.co.uk</a> and we will take steps to ensure that this information is deleted as soon as reasonably practicable.
        </p>
        <p class="mb-4">
          We will not share, sell or distribute any of the information you provide to us (other than as set out in this policy) without your prior consent, unless required to do so by law.
        </p>
      </section>

      <section class="mb-6">
        <h2 class="text-2xl font-semibold mb-4">Third Party Sites</h2>
        <p class="mb-4">
          Our Website contains links to third-party websites, including websites via which you are able to purchase products and services. They are provided for your convenience only and we do not check, endorse, approve or agree with such third-party websites nor the products and/or services offered and sold on them. We have no responsibility for the content, product and/or services of the linked websites. Please ensure you review all terms and conditions of website use and privacy policy of any such third-party websites before use and before you submit any personal data to such websites.
        </p>
      </section>

      <section class="mb-6">
        <h2 class="text-2xl font-semibold mb-4">How safe is your information?</h2>
        <p class="mb-4">
          Where we have given you (or where you have chosen) a password which enables you to access certain parts of the Website, you are responsible for keeping this password confidential. We ask you not to share a password with anyone.
        </p>
        <p class="mb-4">
          Protecting your security and privacy is important to us and we make every effort to secure your information and maintain your confidentiality in accordance with the terms of the Data Protection Legislation. The Website is protected by various levels of security technology, which are designed to protect your information from any unauthorised or unlawful access, processing, accidental loss, destruction and damage.
        </p>
        <p class="mb-4">
          We will do our best to protect your personal data but the transmission of information via the Internet is not completely secure. Any such transmission is therefore at your own risk.
        </p>
      </section>

      <section class="mb-6">
        <h2 class="text-2xl font-semibold mb-4">Disclosure of your information</h2>
        <p class="mb-4">
          We may share your personal information with any member of our group, which means our subsidiaries, our ultimate holding company and its subsidiaries, as defined in section 1159 of the Companies Act 2006.
        </p>
        <p class="mb-4">
          We may share your information with selected third parties including:
        </p>
        <ul class="list-disc pl-5 mb-4">
          <li class="mb-2">Business partners, suppliers, sales agents and sub-contractors for the performance of any contract we enter into with them or you;</li>
          <li class="mb-2">The Rental Providers to provide the Services (such as connecting you with the Rental Provider to book the use of a vehicle, and sharing personal details, contact information, preferences, requests, and other information with such Rental Provider) and for the Rental Provider's own purposes, which may include marketing or advertising purposes;</li>
          <li class="mb-2">Third parties who may wish to contact you in respect of services and/or products they offer or sell which may be of interest to you, provided we receive your consent to such disclosure;</li>
          <li class="mb-2">Advertisers and advertising networks that require the data to select and serve relevant adverts to you and analytics and search engine providers that assist us in the improvement and optimisation of the Website;</li>
          <li class="mb-2">Service providers, including payment card processors. Your credit or debit card information is required to process certain transactions, including to make certain bookings and reservations.</li>
        </ul>
        <p class="mb-4">
          Please note that we may need to disclose your personal information where we:
        </p>
        <ul class="list-disc pl-5 mb-4">
          <li class="mb-2">Sell any or all of our business or assets or we buy another business or assets in which case we may disclose your personal data to the prospective buyer or seller;</li>
          <li class="mb-2">Are under a legal duty to comply with any legal obligation or in order to enforce or apply our terms and conditions;</li>
          <li class="mb-2">Need to disclose it to protect our rights, property or safety of our customers or others, including the exchange of information with other companies, organisations and/or governmental bodies for the purposes of fraud prevention and credit risk reduction.</li>
        </ul>
      </section>

      <section class="mb-6">
        <h2 class="text-2xl font-semibold mb-4">Your rights in respect of your data</h2>
        <p class="mb-4">
          There are a number of rights available to you under GDPR:
        </p>
        <h3 class="text-xl font-semibold mb-2">Access to your information</h3>
        <p class="mb-4">
          You can ask us to confirm that we process your personal data and provide access to and copies of the information we hold about you. We will process your request to access your information and provide this information to you free of charge unless your request is manifestly unfounded or excessive or repetitive, in which case we are entitled to charge a reasonable fee. We may also charge if you request more than one copy of the same information. We will provide the information you request as soon as possible and in any event within one month of receiving your request, unless there are extenuating circumstances. If we need more information to comply with your request, we'll let you know.
        </p>
        <h3 class="text-xl font-semibold mb-2">Rectification of your data</h3>
        <p class="mb-4">
          If you believe personal data we hold about you is inaccurate or incomplete, or any of the information you provide to us changes, please let us know as soon as possible so that we can make the necessary changes to the information we hold for you on our database. We will comply with your request within one month of receiving it, unless we don't feel it's appropriate for us to do so in which case, we'll let you know why. We'll also let you know if we need more time to comply with your request.
        </p>
        <h3 class="text-xl font-semibold mb-2">Right to be forgotten</h3>
        <p class="mb-4">
          In some circumstances, you have the right to ask us to delete personal data we hold about you. This right is available to you:
        </p>
        <ul class="list-disc pl-5 mb-4">
          <li class="mb-2">Where we no longer need your personal data for the purpose for which we collected it;</li>
          <li class="mb-2">Where we have collected your personal data on the grounds of consent and you withdraw that consent;</li>
          <li class="mb-2">Where you object to the processing and we don't have any overriding legitimate interests to continuing processing the data;</li>
          <li class="mb-2">Where we have unlawfully processed your personal data (i.e. we have failed to comply with GDPR);</li>
          <li class="mb-2">Where the personal data has to be deleted to comply with a legal obligation; and</li>
          <li class="mb-2">Where the personal data we process relates to the offer of online services to a child.</li>
        </ul>
        <h3 class="text-xl font-semibold mb-2">Right to restrict / object to processing</h3>
        <p class="mb-4">
          In some circumstances you are entitled to ask us to suppress processing of your personal data. This means we will stop actively processing your personal data but we don't have to delete it. This right is available to you:
        </p>
        <ul class="list-disc pl-5 mb-4">
          <li class="mb-2">If you believe the personal data we hold isn't accurate – we'll cease processing it until we can verify its accuracy;</li>
          <li class="mb-2">If you have objected to us processing the data – we'll cease processing it until we have determined whether our legitimate interests override your objection;</li>
          <li class="mb-2">If the processing is unlawful; or</li>
          <li class="mb-2">If we no longer need the data but you would like us to keep it because you need it to establish, exercise or defend a legal claim.</li>
        </ul>
        <p class="mb-4">
          You are entitled to object to us processing your personal data:
        </p>
        <ul class="list-disc pl-5 mb-4">
          <li class="mb-2">If the processing is based on legitimate interests or performance of a task in the public interest or exercise of official authority;</li>
          <li class="mb-2">For direct marketing purposes (including profiling); and/or</li>
          <li class="mb-2">For the purposes of scientific or historical research and statistics.</li>
        </ul>
        <h3 class="text-xl font-semibold mb-2">Data Portability</h3>
        <p class="mb-4">
          You have the right to ask us to provide your personal data in a structured, commonly used and machine-readable format so that you are able to transmit the personal data to another data controller. This right only applies:
        </p>
        <ul class="list-disc pl-5 mb-4">
          <li class="mb-2">To personal data you provide to us;</li>
          <li class="mb-2">Where processing is based on your consent or for performance of a contract (i.e. the right does not apply if we process your personal data on the grounds of legitimate interests); and</li>
          <li class="mb-2">Where we carry out the processing by automated means.</li>
        </ul>
        <p class="mb-4">
          We'll respond to your request as soon as possible and in any event within one month from the date we receive it. If we need more time, we'll let you know.
        </p>
        <p class="mb-4">
          If you have any complaints about our use of your personal data, please contact us. You are also entitled to report any concerns which you may have to supervisory authority in your jurisdiction. The supervisory authority in the UK is the Information Commissioner's Office. You can call the ICO on 0303 123 1113 or get in touch via other means as set out on the ICO website at <a href="https://ico.org.uk/concerns/" className="text-blue-600 hover:underline" target="_blank" rel="noreferrer">their website</a>.
        </p>
        <p class="mb-4">
          If you have any queries or comments on our privacy policy or would like to exercise any of the rights available to you, please contact us via the following webpage <a href="https://enjoycarhire.com/en/Home/ContactUs" className="text-blue-600 hover:underline" target="_blank" rel="noreferrer">/en/Home/ContactUs</a> or you can contact us by emailing <a href="mailto:web@enjoycarhire.com" className="text-blue-600 hover:underline">web@enjoycarhire.com</a>. We always welcome your views about our Website and our privacy policy.
        </p>
      </section>
    </article>
  </div>
    </div>
  );
};

export default Privacy; 