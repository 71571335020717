import React from 'react';
import { useScriptLoader } from '../hooks/useScriptLoader';

function AirportHotelsResults() {
  useScriptLoader('App');

  return (
    <div  className="mt-[64px]"  id="App"></div>
  );
}

export default AirportHotelsResults; 