import { useEffect } from 'react';
import { initFHRWidget, initBookingJourney } from '../utils/scriptLoader';

export const useScriptLoader = (elementId = 'app') => {
  useEffect(() => {
   
    
    const cleanup = () => {

      const oldScripts = document.querySelectorAll('script[src*="bookingjourney-widget-v3"], script[src*="skypark-lpl"]');
      const oldLinks = document.querySelectorAll('link[href*="bookingjourney-widget-v3"], link[src*="skypark-lpl"]');
      
      
      oldScripts.forEach(script => script.remove());
      oldLinks.forEach(link => link.remove());
    };

    cleanup();

    const targetElement = document.getElementById(elementId);
    if (!targetElement) {
      console.warn(`⚠️ Element with id "${elementId}" not found`);
      return;
    }
    console.log(`✅ Found target element with id "${elementId}"`);

    const timeoutId = setTimeout(() => {
      console.log('⏳ Timeout completed, initializing scripts...');
      
      const fhrElements = initFHRWidget();
      console.log('✨ FHR Widget initialized', fhrElements);
      
      const bookingElements = initBookingJourney();
      console.log('✨ Booking Journey initialized', bookingElements);

      // Vérifier si le DOM est mis à jour après un court délai
      setTimeout(() => {
        const appContent = document.getElementById(elementId)?.innerHTML;
        console.log(`📝 Content of #${elementId} after scripts loaded:`, appContent ? 'Has content' : 'Empty');
        if (!appContent) {
          console.warn(`⚠️ No content found in #${elementId} after scripts loaded`);
        }
      }, 1000);

      return () => {
        console.log('🔄 Cleanup function from setTimeout called');
        cleanup();
        if (fhrElements.script) fhrElements.script.remove();
        if (fhrElements.link) fhrElements.link.remove();
        if (bookingElements.script) bookingElements.script.remove();
        if (bookingElements.link) bookingElements.link.remove();
      };
    }, 100);

    return () => {
      console.log('🔚 Component unmounting, cleaning up');
      clearTimeout(timeoutId);
      cleanup();
    };
  }, [elementId]);
}; 