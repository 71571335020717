import React from 'react';
import { Link } from 'react-router-dom';

const CommercialVehicles = () => {
  return (
    <div className="pt-32 pb-16">
      {/* Hero Section */}
      <div className="bg-gray-100 py-12 mb-12">
        <div className="max-w-7xl mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="text-4xl md:text-5xl font-bold mb-6">Liverpool Commercial Vehicle Parking and Storage</h1>
            <p className="text-xl text-gray-600 mb-8">
              Secure parking and storage for campervans, transit vans, and commercial vehicles up to 3500 KG
            </p>
            <div className="flex justify-center">
              <Link 
                to="/contact" 
                className="bg-skypark-blue text-white px-8 py-3 rounded-sm hover:bg-blue-700 transition-colors"
              >
                  Contact Us Now
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4">
        {/* Introduction */}
        <div className="mb-16">
          <h2 className="text-3xl font-semibold mb-6">Skypark's Unique Commercial Vehicle Service</h2>
          <div className="grid md:grid-cols-2 gap-8">
            <div>
              <p className="text-lg mb-4">
                Skypark is proud to offer Liverpool's premier commercial vehicle parking and storage solution. Located just minutes from Liverpool John Lennon Airport, our secure facility is perfect for businesses and individuals needing reliable parking for larger vehicles.
              </p>
              <p className="text-lg mb-4">
                Whether you need short-term parking while traveling from the airport or long-term secure storage for your commercial vehicle, our dedicated facility offers the perfect solution.
              </p>
              <p className="text-lg font-medium text-skypark-blue">
                Unlike other providers in the area, we can accommodate a wide range of commercial vehicles - making us your go-to choice for commercial vehicle parking in Liverpool.
              </p>
            </div>
            <div className="bg-white shadow-sm p-6 rounded">
              <h3 className="text-xl font-semibold mb-4">We Can Accommodate:</h3>
              <ul className="space-y-3">
                <li className="flex items-center">
                  <span className="text-green-500 mr-2">✓</span>
                  <span>Campervans and motorhomes</span>
                </li>
                <li className="flex items-center">
                  <span className="text-green-500 mr-2">✓</span>
                  <span>Transit vans and delivery vehicles</span>
                </li>
                <li className="flex items-center">
                  <span className="text-green-500 mr-2">✓</span>
                  <span>Commercial vehicles up to 3500 KG</span>
                </li>
                <li className="flex items-center">
                  <span className="text-green-500 mr-2">✓</span>
                  <span>Vehicles up to 6.8 meters in length</span>
                </li>
                <li className="flex items-center">
                  <span className="text-green-500 mr-2">✓</span>
                  <span>Short and long-term storage available</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Key Features Section */}
        <div className="mb-16">
          <h2 className="text-3xl font-semibold mb-8 text-center">Key Features</h2>
          <div className="grid md:grid-cols-4 gap-6">
            <div className="bg-white p-6 shadow-sm rounded text-center">
              <div className="flex justify-center mb-4">
                <span className="text-4xl text-skypark-blue">🚚</span>
              </div>
              <h3 className="text-xl font-semibold mb-2">Variety of Vehicles</h3>
              <p>From campervans to transit vans, we can accommodate a wide range of commercial vehicles.</p>
            </div>
            
            <div className="bg-white p-6 shadow-sm rounded text-center">
              <div className="flex justify-center mb-4">
                <span className="text-4xl text-skypark-blue">📏</span>
              </div>
              <h3 className="text-xl font-semibold mb-2">Size Flexibility</h3>
              <p>Vehicles up to 6.8 meters in length can be easily accommodated in our spacious facility.</p>
            </div>
            
            <div className="bg-white p-6 shadow-sm rounded text-center">
              <div className="flex justify-center mb-4">
                <span className="text-4xl text-skypark-blue">⚖️</span>
              </div>
              <h3 className="text-xl font-semibold mb-2">Weight Capacity</h3>
              <p>We can handle commercial vehicles weighing up to 3500 KG with our robust parking infrastructure.</p>
            </div>
            
            <div className="bg-white p-6 shadow-sm rounded text-center">
              <div className="flex justify-center mb-4">
                <span className="text-4xl text-skypark-blue">📅</span>
              </div>
              <h3 className="text-xl font-semibold mb-2">Flexible Duration</h3>
              <p>Whether you need short-term parking or long-term storage, we offer flexible solutions to meet your needs.</p>
            </div>
          </div>
        </div>

        {/* Security and Facilities */}
        <div className="mb-16">
          <div className="bg-gray-50 p-8 rounded">
            <h2 className="text-3xl font-semibold mb-6">Security and Facilities</h2>
            <div className="grid md:grid-cols-2 gap-8">
              <div>
                <p className="mb-4">
                  At Skypark, we understand the importance of keeping your commercial vehicle safe and secure. Our facility is equipped with:
                </p>
                <ul className="space-y-3">
                  <li className="flex items-start">
                    <span className="text-green-500 mr-2 mt-1">✓</span>
                    <span>24/7 CCTV surveillance covering all parking areas</span>
                  </li>
                  <li className="flex items-start">
                    <span className="text-green-500 mr-2 mt-1">✓</span>
                    <span>Secure fencing and controlled access gates</span>
                  </li>
                  <li className="flex items-start">
                    <span className="text-green-500 mr-2 mt-1">✓</span>
                    <span>Regular security patrols throughout the day and night</span>
                  </li>
                  <li className="flex items-start">
                    <span className="text-green-500 mr-2 mt-1">✓</span>
                    <span>Well-lit parking areas for added security</span>
                  </li>
                  <li className="flex items-start">
                    <span className="text-green-500 mr-2 mt-1">✓</span>
                    <span>On-site staff during business hours</span>
                  </li>
                </ul>
              </div>
              <div className="bg-gray-100 rounded-lg shadow-md flex items-center justify-center h-64 p-6">
                <div className="text-center">
                  <div className="flex justify-center  ">
                  <div className="w-[50px] h-[50px] mb-6" aria-hidden="true">
            <svg className="w-full h-full text-skypark-blue" viewBox="0 0 24 24" fill="currentColor">
              <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm.5-13H11v6l5.25 3.15.75-1.23-4.5-2.67z"/>
            </svg>
          </div>
                  </div>
                  <p className="text-xl font-semibold text-skypark-blue">
                    Secure Commercial Vehicle Storage
                  </p>
                  <p className="text-gray-600 mt-2">
                    24/7 monitored facilities for your peace of mind
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Pricing and Booking */}
        <div className="mb-16">
          <h2 className="text-3xl font-semibold mb-6">Commercial Vehicle Parking Prices</h2>
          <p className="text-lg mb-6">
            Our competitive rates for commercial vehicles are structured based on the length of your stay. All prices below are in GBP (£).
          </p>
          
          <div className="overflow-x-auto bg-white rounded-lg shadow-md">
            <table className="w-full border-collapse">
              <thead>
                <tr className="bg-skypark-blue text-white">
                  <th className="px-6 py-4 text-left font-semibold rounded-tl-lg">Duration</th>
                  <th className="px-6 py-4 text-right font-semibold">Price (£)</th>
                  <th className="px-6 py-4 text-left font-semibold">Duration</th>
                  <th className="px-6 py-4 text-right font-semibold rounded-tr-lg">Price (£)</th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-b border-gray-200 hover:bg-gray-50">
                  <td className="px-6 py-3 text-left">1 Day</td>
                  <td className="px-6 py-3 text-right font-medium">46.00</td>
                  <td className="px-6 py-3 text-left">16 Days</td>
                  <td className="px-6 py-3 text-right font-medium">181.90</td>
                </tr>
                <tr className="border-b border-gray-200 bg-gray-50 hover:bg-gray-100">
                  <td className="px-6 py-3 text-left">2 Days</td>
                  <td className="px-6 py-3 text-right font-medium">68.00</td>
                  <td className="px-6 py-3 text-left">17 Days</td>
                  <td className="px-6 py-3 text-right font-medium">185.90</td>
                </tr>
                <tr className="border-b border-gray-200 hover:bg-gray-50">
                  <td className="px-6 py-3 text-left">3 Days</td>
                  <td className="px-6 py-3 text-right font-medium">79.00</td>
                  <td className="px-6 py-3 text-left">18 Days</td>
                  <td className="px-6 py-3 text-right font-medium">187.90</td>
                </tr>
                <tr className="border-b border-gray-200 bg-gray-50 hover:bg-gray-100">
                  <td className="px-6 py-3 text-left">4 Days</td>
                  <td className="px-6 py-3 text-right font-medium">82.90</td>
                  <td className="px-6 py-3 text-left">19 Days</td>
                  <td className="px-6 py-3 text-right font-medium">191.90</td>
                </tr>
                <tr className="border-b border-gray-200 hover:bg-gray-50">
                  <td className="px-6 py-3 text-left">5 Days</td>
                  <td className="px-6 py-3 text-right font-medium">92.90</td>
                  <td className="px-6 py-3 text-left">20 Days</td>
                  <td className="px-6 py-3 text-right font-medium">193.90</td>
                </tr>
                <tr className="border-b border-gray-200 bg-gray-50 hover:bg-gray-100">
                  <td className="px-6 py-3 text-left">6 Days</td>
                  <td className="px-6 py-3 text-right font-medium">108.90</td>
                  <td className="px-6 py-3 text-left">21 Days</td>
                  <td className="px-6 py-3 text-right font-medium">197.90</td>
                </tr>
                <tr className="border-b border-gray-200 hover:bg-gray-50">
                  <td className="px-6 py-3 text-left">7 Days</td>
                  <td className="px-6 py-3 text-right font-medium">122.90</td>
                  <td className="px-6 py-3 text-left">22 Days</td>
                  <td className="px-6 py-3 text-right font-medium">233.90</td>
                </tr>
                <tr className="border-b border-gray-200 bg-gray-50 hover:bg-gray-100">
                  <td className="px-6 py-3 text-left">8 Days</td>
                  <td className="px-6 py-3 text-right font-medium">119.90</td>
                  <td className="px-6 py-3 text-left">23 Days</td>
                  <td className="px-6 py-3 text-right font-medium">243.90</td>
                </tr>
                <tr className="border-b border-gray-200 hover:bg-gray-50">
                  <td className="px-6 py-3 text-left">9 Days</td>
                  <td className="px-6 py-3 text-right font-medium">135.90</td>
                  <td className="px-6 py-3 text-left">24 Days</td>
                  <td className="px-6 py-3 text-right font-medium">253.90</td>
                </tr>
                <tr className="border-b border-gray-200 bg-gray-50 hover:bg-gray-100">
                  <td className="px-6 py-3 text-left">10 Days</td>
                  <td className="px-6 py-3 text-right font-medium">145.90</td>
                  <td className="px-6 py-3 text-left">25 Days</td>
                  <td className="px-6 py-3 text-right font-medium">263.90</td>
                </tr>
                <tr className="border-b border-gray-200 hover:bg-gray-50">
                  <td className="px-6 py-3 text-left">11 Days</td>
                  <td className="px-6 py-3 text-right font-medium">153.90</td>
                  <td className="px-6 py-3 text-left">26 Days</td>
                  <td className="px-6 py-3 text-right font-medium">273.90</td>
                </tr>
                <tr className="border-b border-gray-200 bg-gray-50 hover:bg-gray-100">
                  <td className="px-6 py-3 text-left">12 Days</td>
                  <td className="px-6 py-3 text-right font-medium">161.90</td>
                  <td className="px-6 py-3 text-left">27 Days</td>
                  <td className="px-6 py-3 text-right font-medium">283.90</td>
                </tr>
                <tr className="border-b border-gray-200 hover:bg-gray-50">
                  <td className="px-6 py-3 text-left">13 Days</td>
                  <td className="px-6 py-3 text-right font-medium">167.90</td>
                  <td className="px-6 py-3 text-left">28 Days</td>
                  <td className="px-6 py-3 text-right font-medium">293.90</td>
                </tr>
                <tr className="border-b border-gray-200 bg-gray-50 hover:bg-gray-100">
                  <td className="px-6 py-3 text-left">14 Days</td>
                  <td className="px-6 py-3 text-right font-medium">173.90</td>
                  <td className="px-6 py-3 text-left">29 Days</td>
                  <td className="px-6 py-3 text-right font-medium">303.90</td>
                </tr>
                <tr className="hover:bg-gray-50">
                  <td className="px-6 py-3 text-left rounded-bl-lg">15 Days</td>
                  <td className="px-6 py-3 text-right font-medium">177.90</td>
                  <td className="px-6 py-3 text-left">30 Days</td>
                  <td className="px-6 py-3 text-right font-medium rounded-br-lg">313.90</td>
                </tr>
              </tbody>
            </table>
          </div>
          
          <div className="mt-8 text-center">
            <p className="text-lg mb-6">
              For stays longer than 30 days or for special requirements, please contact our customer service team for custom quotes.
            </p>
            <Link 
              to="/" 
              className="inline-block bg-skypark-blue text-white px-8 py-3 rounded hover:bg-blue-700 transition-colors shadow-md"
            >
              Book Commercial Vehicle Parking
            </Link>
          </div>
        </div>

        {/* FAQ Section */}
        <div className="mb-16">
          <h2 className="text-3xl font-semibold mb-8 text-center">Frequently Asked Questions</h2>
          <div className="space-y-4">
            <div className="bg-white p-6 shadow-sm rounded">
              <h3 className="text-xl font-semibold mb-2">Is advance booking required?</h3>
              <p>
                Yes, we recommend booking in advance to ensure availability, especially during peak travel seasons. This also allows us to prepare for your commercial vehicle's specific requirements.
              </p>
            </div>
            
            <div className="bg-white p-6 shadow-sm rounded">
              <h3 className="text-xl font-semibold mb-2">Can I access my vehicle during storage?</h3>
              <p>
                Yes, you can access your vehicle during our opening hours. For long-term storage customers, we may require 24 hours notice to ensure your vehicle is accessible.
              </p>
            </div>
            
            <div className="bg-white p-6 shadow-sm rounded">
              <h3 className="text-xl font-semibold mb-2">What is the maximum vehicle size you can accommodate?</h3>
              <p>
                We can accommodate vehicles up to 6.8 meters in length and weighing up to 3500 KG. If your vehicle exceeds these dimensions, please contact us to discuss your specific requirements.
              </p>
            </div>
            
            <div className="bg-white p-6 shadow-sm rounded">
              <h3 className="text-xl font-semibold mb-2">Is there a shuttle service available for commercial vehicle customers?</h3>
              <p>
                Yes, our shuttle service is available for all customers, including those parking commercial vehicles. The shuttle runs regularly between our facility and Liverpool John Lennon Airport.
              </p>
            </div>
          </div>
        </div>

        {/* CTA Section */}
        <div className="bg-skypark-blue text-white p-8 rounded text-center">
          <h2 className="text-3xl font-semibold mb-4">Ready to Book Your Commercial Vehicle Parking?</h2>
          <p className="text-xl mb-6">
            Secure, convenient, and flexible commercial vehicle parking at Liverpool's premier facility
          </p>
          <Link 
            to="/contact" 
            className="inline-block bg-white text-skypark-blue px-8 py-3 rounded-sm hover:bg-gray-100 transition-colors font-medium"
          >
            Contact Us Now
          </Link>
          <p className="mt-4">
            Or call us at <a href="tel:+441514480300" className="underline hover:no-underline">+44 (0)151 448 0300</a> for more information
          </p>
        </div>
      </div>
    </div>
  );
};

export default CommercialVehicles; 