export const initFHRWidget = () => {
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = 'https://bookingjourney-widget-v3.netlify.app/bundle.css';
  document.head.appendChild(link);

  const script = document.createElement('script');
  script.src = 'https://bookingjourney-widget-v3.netlify.app/bundle.js';
  script.async = true;
  document.body.appendChild(script);

  return { link, script };
};

export const initBookingJourney = () => {
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = 'https://skypark-lpl.netlify.app/index.css';
  document.head.appendChild(link);

  const script = document.createElement('script');
  script.type = 'module';
  script.src = 'https://skypark-lpl.netlify.app/index.js';
  script.async = true;
  document.body.appendChild(script);

  return { link, script };
}; 

