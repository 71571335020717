import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import About from './components/About';
import Features from './components/Features';
import Footer from './components/Footer';
import AboutUs from './pages/AboutUs';
import Location from './pages/Location';
import Services from './pages/Services';
import Faqs from './pages/Faqs';
import Contact from './pages/Contact';
import Reservations from './pages/Reservations';
import AirportParkingResults from './pages/AirportParkingResults';
import AirportHotelsResults from './pages/AirportHotelsResults';
import AirportLoungesResults from './pages/AirportLoungesResults';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import CommercialVehicles from './pages/CommercialVehicles';
import { Toaster } from 'react-hot-toast';
import Checkout from './pages/Checkout';
function App() {
  return (
    <Router>
      <div className="min-h-screen flex flex-col">
        <Toaster position="top-center" />
        <Navbar />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={
              <>
                <Hero />
                <About />
                <Features />
              </>
            } />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/location" element={<Location />} />
            <Route path="/services" element={<Services />} />
            <Route path="/faqs" element={<Faqs />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/reservations" element={<Reservations />} />
            <Route path="/airport-parking/results" element={<AirportParkingResults />} />
            <Route path="/airport-hotels/results" element={<AirportHotelsResults />} />
            <Route path="/airport-lounges/results" element={<AirportLoungesResults />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/terms-and-conditions" element={<Terms />} />
            <Route path="/privacy-notice" element={<Privacy />} />
            <Route path="/commercial-vehicles" element={<CommercialVehicles />} />
            {/* Add other routes as needed */}
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App; 